.fulfillment-versions-main {
  display: flex;
  flex-direction: column;

  border: 1px solid #5e5e5e;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 200;
  padding: 10px 20px;
  margin: 10px 0px;

  /*height: 100px;
  overflow: scroll;*/
}

.fulfillment-versions-main > h3 {
  margin: 0px;
}

.fulfillment-version-hit {
  display: flex;
  margin: 10px 0px;
}

.fulfillment-version-date {
  width: 120px;
  margin-right: 10px;
}

.fulfillment-version-hit > div > p {
  margin: 0px 0px 5px 0px;
  word-break: break-word;
}

@media only screen and (max-width: 800px) {
  .fulfillment-versions-main {
    display: none;
  }
}
