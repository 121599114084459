.route-details-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.route-details-modal-container h1 {
  font-size: 24px;
}

.route-details-modal-container > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 800px;
}

.route-details-modal-container > div ul {
  list-style-type: none;
}

.route-details-modal-container > div ul li {
  margin: 5px 0;
  font-size: 18px;
}

.route-details-modal-container > table {
  max-width: 800px;
  width: 50%;
  margin-top: 20px;
  text-align: center;
}

.route-details-modal-container > table > thead {
  font-weight: bold;
}

.route-details-modal-container > table > tbody > tr {
  text-align: center;
  align-self: center;
  outline: 1px solid #999;
  list-style-type: none;
}

.route-details-modal-container > table > tbody > tr > td {
  margin: 0;
  padding: 0;
}

.route-details-modal-container > table > tbody > tr > td > ul {
  list-style-type: none;
  margin: 5px 0;
  padding: 0;
}

.route-details-modal-container > table > tbody > tr > td > ul > li > p {
  padding: 0;
  margin: 10px 0 5px;
}

.route-details-modal-alert {
  font-weight: 100;
  font-size: 12px;
  color: red;
  margin: 0 !important;
  padding: 0;
}
