.edit-or-reset-main-form {
  display: flex;
}

.edit-or-reset-main-form > form > input {
  width: 50px;
  margin-right: 10px;
}

.edit-or-reset-main-form > button {
  width: 35px;
  margin-right: 7px;
  padding: 0px;
}

.edit-or-reset-main-form > form {
  display: flex;
}

.edit-or-reset-main-form > form > button {
  width: 35px;
  margin-right: 7px;
  padding: 0px;
}

.edit-or-reset-main > button {
  width: 35px;
  margin-right: 7px;
  padding: 0px;
}

.edit-or-reset-main {
  display: flex;
}
