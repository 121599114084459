.line-item-list-main {
  border: 1px solid #5e5e5e;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 200;
  padding: 10px 20px;
  margin: 10px 0px;

  /*height: 450px;
  overflow-y: scroll;*/
}

.line-item-list-header {
  display: flex;
}

.line-item-list-header > h3 {
  margin: 0px 0px 5px 0px;
}

.line-item-list-weight-limited,
.line-item-list-print-sale-receipt {
  margin: auto 0 auto 2px;
  float: right;
  align-self: flex-end;
}

.line-item-list-white-space {
  margin: auto;
  display: flex;
}

.line-item-list-icons-info {
  display: flex;
}

.line-item-list-main > p {
  color: white;
  font-weight: bold; 
}
