.statistics-preparation-table-main {
  width: 100%;
  margin-top: 15px;
}

.statistics-preparation-table-main > div > table {
  width: 100%;
  border: 1px solid #aeaeae;
  text-align: left;
  padding: 5px;
  border-collapse: collapse;
}

.statistics-preparation-table-main tr {
  border-bottom: 1px solid #aeaeae !important;
}

.statistics-preparation-table-main th {
  text-align: left;
  padding: 5px 5px 5px 10px;

  font-weight: 400;
}

.statistics-preparation-table-main td {
  text-align: left;
  min-width: 50px;
  padding: 5px 5px 5px 10px;
  font-weight: 200;
}

.statistics-preparation-table-main tr:hover {
  background-color: #e6e6e6;
  cursor: pointer;
}
