.drop-down-distribution-center {
  width: 116px;
  display: inline-grid;
  font-size: 14px;
  margin-top: 5px;
}
.drop-down-distribution-center > div {
  margin-left: 3px;
}
.drop-down-distribution-center > select {
  height: 24px;
  margin-top: 2px;
}

@media only screen and (max-width: 750px) {
  .drop-down-distribution-center {
    width: 100%;
  }
}
