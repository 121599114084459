.search-input-container {
  position: relative;
}

.address-search-container-box > input,
.address-search-container-box > input:focus,
.address-search-container-box > input:active {
  background-color: #f6f6f6;
  border: 1px solid #ffffff;
  font-size: 14px;
  font-weight: 200;
  text-indent: 15px;
  letter-spacing: 1px;
  height: 35px;
  width: 95%;
  padding-bottom: 1px;
  margin-bottom: 5px;
  box-sizing: border-box;
  outline: none;
}

.address-search-container-autocomplete-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 2px 2px;
  font-size: 14px;
  font-weight: 100;
  position: absolute;
  z-index: 1;
  left: 10%;
  width: 80%;
}

.address-search-dropdown-footer {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
  background: #fff;
}

.address-search-dropdown-footer > img {
  display: inline-block;
  width: 150px;
}

.address-serach-autocomplete-suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #ffffff;
  cursor: pointer;
}

.address-serach-autocomplete-suggestion-item-active {
  padding: 8px;
  text-align: left;
  background-color: #a5a5a5;
  cursor: pointer;
}

.address-search-container {
  position: relative;
  margin-top: 10px;
}

.address-search-container-box {
  display: flex;
  flex-direction: row;
  margin: 0 100px;
}

.address-search-container-box-icon {
  width: 5%;
  height: 29px;
  border-radius: 0 3px 3px 0;
  border: 2px solid #8e8e8e;
  background-color: #8e8e8e;
  display: flex;
  justify-content: center;
  align-items: center;
}
