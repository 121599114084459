.visit-details-modal-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.visit-details-modal-main h1 {
  font-size: 24px;
}

.visit-details-modal-item-information {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.visit-details-modal-item-information > div {
  margin: 10px;
}

.visit-details-modal-item-information img {
  max-width: 400px;
  width: 100%;
}

.visit-details-modal-signature {
  border: 1px solid black;
}

.visit-detail-modal-document-information {
  margin-left: 5px;
  text-transform: uppercase;
}

.visit-details-modal-main > table {
  max-width: 800px;
  width: 50%;
  margin-top: 20px;
  text-align: center;
}

.visit-details-modal-main > table > thead {
  font-weight: bold;
}

.visit-details-modal-main > table > tbody > tr {
  text-align: center;
  align-self: center;
  outline: 1px solid #999;
  list-style-type: none;
}

.visit-details-modal-main > table > tbody > tr > td {
  margin: 0;
  padding: 0;
}

.visit-details-modal-main > table > tbody > tr > td > ul {
  list-style-type: none;
  margin: 5px 0;
  padding: 0;
}

.visit-details-modal-main > table > tbody > tr > td > ul > li > p {
  padding: 0;
  margin: 10px 0 5px;
}
