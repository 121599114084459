.statistics-page-main {
  margin: 0px 10px;
}

.statistics-page-main > div {
  margin-bottom: 15px;
  border: 1px solid #5e5e5e;
  border-radius: 4px;
  padding: 10px 20px;
}

.statistics-page-main > div > h3 {
  margin-top: 0px;
  margin-bottom: 10px;
}

.statistics-chart-container {
  font-size: 14px;
  font-weight: 180;
  width: 100%;
  height: 200px;
  margin: 25px 0px 0px -30px;
  padding: 0px 0px 30px 10px;
}

@media only screen and (max-width: 750px) {
  .statistics-chart-container {
    margin: 25px 0px 0px -60px;
    width: 110%;
    padding: 0px 0px 30px 10px;
  }

  .statistics-page-main > div {
    padding: 10px 10px;
  }
}

@media only screen and (max-width: 350px) {
  .statistics-chart-container {
    width: 130%;
  }
}
