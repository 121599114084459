.fulfillment-item-main {
  display: flex;
  flex-direction: column;
}

.fulfillment-flagged {
  background-color: #ff9999;
}

.fulfillment-item-line {
  width: 100%;
}

.fulfillment-item-container {
  display: flex;
}

.fulfillment-item-content {
  color: black;
  text-decoration: none;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fulfillment-item-content-bold {
  font-weight: bold;
}

.fulfillment-item-content > p {
  margin: 5px 5px;
}

.fulfillment-item-content-small {
  width: 125px !important;
}

.fulfillment-item-content-medium {
  width: 170px !important;
}

.fulfillment-item-content-big {
  width: 200px !important;
}

.fulfillment-item-content-large {
  width: 310px !important;
}

.fulfillment-item-content:hover {
  background-color: #ededed;
  cursor: pointer;
}

.fulfillment-item-status {
  text-align: right;
}

.fulfillment-item-order-number-and-weight {
  font-weight: 700;
  text-align: left;
  display: flex;
  width: 120px;
}

.fulfillment-item-weight-limited,
.fulfillment-item-print-sale-receipt {
  margin: auto 0 auto 2px;
}

.fulfillment-item-order-number-and-weight > p {
  margin-right: 5px;
}

.fulfillment-item-user-or-company-icon {
  font-size: 18px;
  margin: auto 5px auto 0;
  width: 18px;
}
@media only screen and (max-width: 1128px) {
  .fulfillment-item-content {
    width: 98%;
  }
}
@media only screen and (max-width: 750px) {
  .fulfillment-item-content {
    justify-content: flex-start;
    flex-wrap: wrap;
    height: auto;
  }

  .fulfillment-item-content > p {
    width: auto;
    margin: 5px 0px;
  }

  .fulfillment-item-hidden-on-mobile {
    display: none;
  }
  .fulfillment-item-order-number-and-weight {
    width: auto;
    height: 24px;
  }
  .fulfillment-item-order-number-and-weight > p {
    margin: auto 5px auto 0;
  }
  .fulfillment-item-status {
    text-align: left;
  }
}

@media only screen and (max-width: 375px) {
  .fulfillment-item-content > p {
    width: auto;
    margin: 5px 0px;
  }
}
