.show-all-preparation-images-modal-preparation-image-large {
  width: 60%;
  height: auto;
  z-index: 2;
}

.show-all-preparation-images-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.show-all-preparation-images-modal-close-modal-button {
  justify-content: center;
  margin: auto;
  color: #ffffff;
  font-weight: 900;
  font-size: 20px;
  text-align: center;

  border-radius: 0px;

  border: 0px solid #fff;
  box-shadow: #fff;

  cursor: pointer;

  width: 40%;
  padding: 10px;

  border-radius: 15px;

  background-color: #ce3c25;
}
