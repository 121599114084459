.simple-header-main {
  background-color: #fff;
  font-weight: 200;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 70px;
  margin-top: 5px;
}

.simple-header-main > a {
  width: 90%;
  max-width: 287px;
  height: auto;
}

.simple-header-logo {
  width: 100%;
  max-width: 287px;
  height: auto;
}

.simple-header-line {
  width: 100%;
  margin: 0px;
}
