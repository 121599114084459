.fulfillment-filter-main {
  background-color: #ededed;
  font-size: 14px;
  font-weight: 200;
  padding: 5px 20px 10px 20px;
}

.fulfillment-filter-main > h3 {
  margin: 0px;
  font-size: 18px;
}

.fulfillment-filter-form-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px 15px 0px;
  min-height: 25px;
}

.fulfillment-filter {
  display: flex;
  flex-direction: column;
  width: initial;
  max-width: 200px;
  margin-right: 10px;
}

.fulfillment-filter > select {
  width: 100%;
  height: 24px;
  margin-top: 2px;
}

.fulfillment-filter > select:hover {
  cursor: pointer;
}

.fulfillment-filter-input {
  height: 20px;
  border: 1px solid #8e8e8e;
  border-radius: 4px;
  margin-top: 2px;
  text-indent: 2px;
}

.fulfillment-filter-form-container > button {
  width: 100%;
  max-width: 200px;
  font-size: 14px;
  padding: 0px;
  margin-top: 15px;
  border-radius: 2px;
}

.fullfillment-filter-toggler {
  text-align: right;
  font-size: 22px;
  font-weight: 700;
}

.fullfillment-filter-toggler:hover {
  cursor: pointer;
}

.fulfillment-filter-untoggled-with-getter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fulfillment-date-filters {
  display: flex;
  margin-top: 2px;
  justify-content: space-between;
  max-width: 300px;
}

.fulfillment-filter > input[type="checkbox"] {
  margin: 7px auto;
}

.fulfillment-filter-quantity-of-order {
  margin: 5px 0 auto auto;
  display: flex;
  width: fit-content;
}

@media only screen and (max-width: 1050px) {
  .fulfillment-filter-form-container > button {
    font-size: 12px;
  }

  .fulfillment-filter-form-container > button > .button-row {
    padding: 0px !important;
  }

  .fulfillment-filter {
    width: 120px;
  }

  .fulfillment-date-filters {
    width: 220px;
  }
}

@media only screen and (max-width: 750px) {
  .fulfillment-filter-main {
    padding: 5px 20px;
  }

  .fulfillment-filter {
    width: 100%;
    max-width: 500px;
    margin-bottom: 5px;
  }

  .fulfillment-filter > select {
    height: 35px;
  }

  .fulfillment-filter-input {
    height: 30px;
    font-size: 16px;
  }

  .fulfillment-filter-form-container {
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0px;
  }

  .fulfillment-filter-form-container > button {
    margin-bottom: 15px;
  }

  .fulfillment-date-filters {
    text-align: center;
    width: 100%;
    max-width: 500px;
    margin: 0;
  }

  .fulfillment-filter > input[type="checkbox"] {
    margin: 7px 0;
  }
}
