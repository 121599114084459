.visit-modal-style {
  position: absolute;
  left: 50%;
  top: 50%;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(235, 91, 30);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  width: 70%;
  height: 90vh;
  transform: translateX(-50%) translateY(-50%);
}

.visit-item-wrapper {
  max-width: 800px;
  justify-content: center;
  margin: 0 auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.visit-item-header {
  display: flex;
  align-items: center;
}

.visit-item-main-editor-title {
  font-size: 20px;
  /* margin-bottom: 0px; */
  width: 100%;
  /* text-align: center; */
  /* justify-self: center; */
  letter-spacing: 2px;
  word-spacing: 3px;
  flex: 1;
}

.visit-item-main-editor-subtitle {
  font-size: 15px;
  text-align: center;
  letter-spacing: 2px;
  word-spacing: 3px;
}

.visit-card {
  border-radius: 5px;
  margin: 0 5px 5px;
  padding: .8rem;
  width: auto;
  background-color: lightgray;
}

.visit-card-near-end-time-alert {
  background-color: #D14630 !important;
  color: #ffffff !important;
}

.virtual-visit-card {
  border-radius: 5px;
  margin: 0 5px 5px;
  padding: 5px;
  width: auto;
  background-color: #ffe0bd;
}

.visit-card-alert-card {
  background-color: #eb5b1e !important;
  color: #ffffff !important;
}

.visit-card-info-line {
  display: flex;
  align-items: center;
  padding: 2px 0;
}

.visit-card-info-line-additional-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 2px;
  line-height: 0em;
  font-size: 10px;
  padding: 2px 0;
}

.visit-card-info-line-additional-info>span {
  display: flex;
  align-items: center;
}

.visit-card-info-line-additional-info>span>p {
  padding-left: 3px;
}

.visit-card-info-line>p {
  margin-left: 5px;
  line-height: 0em;
  font-size: 10px;
  white-space: pre-wrap;
}

.visit-card-info-line-tasks {
  font-size: 10px;
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
  padding: 2px 0;
}

.visit-card-info-line-tasks>p {
  margin: 0px;
}

.visit-card-near-end-time-alert .visit-card-info-line-tasks a {
  color: #96CAFA;
}

.visit-card>p {
  line-height: 0em;
  font-size: 12px;
  white-space: pre-wrap;
  font-weight: bolder;
}

.visit-card-internal-button {
  width: 16px;
  margin: 0 2px;
  float: right;
  border: none;
  background-color: lightgray;
  color: #ce3c25;
  /* White text */
  font-size: 15px;
  /* Set a font size */
  cursor: pointer;
  /* Mouse pointer on hover */
  border-radius: 50%;
}

.visit-card-add-task {
  width: 32px;
  float: right;
  border: none;
  background-color: #fff;
  color: #000000;
  font-size: 15px;
  cursor: pointer;
  border-radius: 50%;
}

.visit-item-modal-delete-visit {
  float: right;
  border: none;
  background-color: #fff;
  color: red;
  font-size: 15px;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  margin-right: 100px;
  margin-top: 1px;
}

.edit-card-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.visit-card-edit-save-button {
  width: 400px;
}

.visit-item-main-datepicker-selector {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.visit-item-main-datepicker-selector-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
}

.visit-item-main-datepicker-selector-line>p {
  /* width: 50px; */
  text-align: start;
  margin: 0 12px;
}

.visit-item-main-task {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.visit-item-main-task-add-button {
  width: 200px;
  /* float: right;
  margin-top: -31px;
  margin-right: 100px; */
}

.visit-item-list-of-tasks {
  border: 1px solid black;
  padding: 10px;
  /* margin: 0px 100px; */
}

.visit-item-state-properties {
  line-height: 0em;
  font-size: 12px;
  white-space: pre-wrap;
  font-weight: bolder;
}

.visit-item-color-failed {
  color: red;
  font-weight: bolder;
}

.visit-item-color-success {
  color: green;
  font-weight: bolder;
}

.visit-item-color-pending {
  color: orange;
  font-weight: bolder;
}

.visit-item-delete-visit-button {
  width: 45px;
  /* position: absolute; */
  /* margin-top: 19px; */
  /* margin-right: 20px; */
  /* margin-left: 84.5%; */
}

.visit-item-loading {
  display: flex;
  justify-content: center;
  height: 16px;
  width: 16px;
  margin: 5px;
}

@media screen and (max-width: 650px) {
  .visit-item-wrapper {
    padding: 12px;
    max-width: 100%;
  }

  .visit-item-main-editor-subtitle {
    text-align: left;
  }

  .address-form-main .address-form-line {
    flex-direction: column;
  }

  .visit-item-main-datepicker-selector-line {
    flex-direction: column;
    align-items: start;
    width: 100%;
  }

  .visit-item-main-datepicker-selector-line>p {
    margin: 0;
  }

  .visit-item-main-datepicker-selector-line input {
    max-width: 100%;

    width: 100%;
  }

  .visit-item-main-datepicker-selector-line>.react-datepicker-wrapper {
    margin: 8px 0;
  }
}
