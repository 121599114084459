.state-dropdown {
  display: flex;
  flex-direction: column;
  width: 200px;
  margin-top: 5px;
}

.state-dropdown > select {
  height: 24px;
  margin-top: 2px;
}

.state-dropdown > div {
  margin-left: 3px;
}
