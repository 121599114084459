.color-bar {
  width: 10px;
  /*height: 46px;*/
  margin: 0 5px;
}
.color-bar-error {
  background-color: #ff0000;
}
.color-bar-no-route {
  background-color: #a956a9;
}
.color-bar-attention {
  background-color: #fbff00;
}
.color-bar-ok {
  background-color: #04ff00;
}
/*@media only screen and (max-width: 750px) {
  .color-bar{
    height: 56px;
  }
}*/
