.pipeline-item-container {
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
}

.pipeline-item-content {
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.pipeline-item-container h1,
.pipeline-item-container p {
  margin: 0;
}

.pipeline-item-container h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: rgba(30, 30, 30, 1);
}

.pipeline-item-container p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: rgba(30, 30, 30, 0.6);
}

.pipeline-item-container.dragging {
  opacity: 0.5;
}
