.pipeline-main-container {
  background-color: #EDF2F4;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border: 1px solid rgba(82, 82, 81, 0.4);
  border-radius: 8px;
  height: 85vh;
  max-height: 85vh;
  align-items: center;
  min-width: 265px;
  flex: 0 0 auto;
}

.lost-leads-pipeline {
  background-color: rgba(242, 166, 154, 0.4) !important;
}

.in-day-pipeline {
  background-color: rgba(192, 232, 183, 0.4) !important;
}

.pipeline-text-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-self: flex-start;
  padding: 0 1.5rem;
}

.pipeline-title {
  margin: 0;
  width: 230px;
  height: 50px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: rgba(30, 30, 30, 1);
}

.pipeline-bottom-content {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;
}

.activate-scroll-bar {
  overflow-y: auto !important;
}

.pipeline-show-more-button {
  background-color: transparent;
  color: rgb(82, 82, 81);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
}

.pipeline-bottom-content::-webkit-scrollbar {
  width: 6px;
}

.pipeline-bottom-content::-webkit-scrollbar-thumb {
  background-color: rgba(206, 60, 37, 1);
  border-radius: 8px;
}

.pipeline-bottom-content::-webkit-scrollbar-track {
  background-color: #EDF2F4;
  ;
  border-radius: 8px;
}

.pipeline-items-main-container {
  padding: 0 1rem;
}

.pipeline-quantities {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: rgba(30, 30, 30, 1);
  width: 100%;
}

.pipeline-items-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.pipeline-items-list {
  width: 100%;
}

.pipeline-main-container.pipeline-is-over {
  background-color: rgba(0, 255, 0, 0.1);
}
