.unauthorized-main-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90vh;
}

.unauthorized-message-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: .5rem;
}

.unauthorized-title {
  font-weight: 700;
  font-size: 64px;
  color: rgba(30, 30, 30, 1);
  text-align: center;
  margin: 0;
}

.unauthorized-text {
  font-weight: 400;
  font-size: 24px;
  color: rgba(30, 30, 30, 1);
  margin: 0;
  text-align: center;
  width: 75%;
}
