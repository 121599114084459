.header-main {
  background-color: #fff;
  font-weight: 200;

  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 50px;
  padding: 0px 20px;
}

.header-navigation-container {
  display: flex;
  justify-content: space-between;
  min-width: 360px;
  gap: 1rem;
}

.header-navigation-container a {
  text-decoration: none !important;
}

.header-logo {
  width: 100%;
  max-width: 200px;
  /* height: 20px; */
}

.header-menu {
  margin: 0px !important;
  display: none !important;
}

.header-version-information {
  font-size: 11px;
  margin-left: 5px;
}

@media only screen and (max-width: 750px) {
  .header-menu {
    display: flex !important;
    font-size: 30px;
    align-self: center;
    color: #000;
    margin: 0px 0px 0px 10px !important;
  }

  .header-navigation-container {
    display: none !important;
  }

  .header-version-information {
    display: none;
  }
}
