.fulfillment-editor-main {
  border: 1px solid #5e5e5e;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 200;
  padding: 10px 20px;
  height: auto;
}

.fulfillment-editor-main > h3 {
  margin: 0px 0px 0px 0px;
}

.fulfillment-editor-button-container > button {
  width: 450px;
}

.fulfillment-editor-button-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-direction: row;
  align-items: center;
}

.fulfillment-editor-button-container > button {
  width: 300px;
  height: 35px;
}

.fulfillment-editor-form-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  flex-wrap: wrap;
}

.fulfillment-editor {
  display: flex;
  flex-direction: column;
  width: 200px;
  margin-top: 5px;
  margin-right: 15px;
}

.fulfillment-editor > select {
  height: 24px;
  margin-top: 2px;
}

.fulfillment-editor > div {
  margin-left: 3px;
}

.fulfillment-loading {
  width: 150px;
  font-size: 25px;
}
.fulfillment-editor-error-reason {
  margin-top: 10px;
}

.fulfillment-editor-container {
  display: flex;
}

.fulfillment-central-div {
  width: 30%;
  display: flex;
}

.flagged-button-div {
  margin: 15px 0 auto 35px;
  width: 180px;
}

.flagged-button-div > .button-main > .button-row {
  padding: 0;
}
@media only screen and (max-width: 750px) {
  .fulfillment-editor-button-container > button {
    width: 140px;
    font-size: 10px;
  }

  .flagged-button-div {
    margin: 15px 0 0 0;
    width: 105px;
  }

  .fulfillment-editor-button-container > button > .button-row {
    padding: 0px;
  }

  .fulfillment-loading {
    text-align: center;
    width: 150px;
    font-size: 25px;
    margin: 18px auto 0 auto;
  }

  .fulfillment-editor-container {
    width: 100%;
    margin-top: 10px;
    display: block;
  }

  .fulfillment-editor {
    margin: 5px auto;
    width: 50%;
  }

  .fulfillment-central-div {
    display: block;
    margin: 0 10px;
  }

  .flagged-button-div > button {
    font-size: 10px;
  }
}

@media only screen and (max-width: 400px) {
  .fulfillment-editor-button-container > button {
    margin-top: 5px;
  }

  .flagged-button-div {
    margin: 15px 0 0 0;
    width: 94px;
  }
}
