.fulfillment-sort-list-main {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.fulfillment-sort-list-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  color: #000;
  font-size: 14px;
  letter-spacing: 1px;
}

.fulfillment-sort-list-blocking-loader-main {
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  /* padding: 60px 0px 60px 0px; */

  background: rgba(255, 255, 255, 0.4);
}

.fulfillment-sort-list-blocking-loader-sub {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fulfillment-sort-list-blocking-loader-sub > i {
  margin-bottom: 25px;
}

@media only screen and (max-width: 750px) {
  .fulfillment-sort-list-blocking-loader-sub {
    margin: 5px;
  }
}
