.line-chart-main {
  width: 90%;
  height: 100%;
  display: block;
  margin: auto;
}

.recharts-surface {
  margin-top: 0px;
  padding-bottom: 50px;
  cursor: pointer;
}

.recharts-cartesian-axis-tick-value {
  font-size: 14px !important;
  color: #000 !important;
  fill: #000 !important;
}

.hide-tool-tip-text .recharts-tooltip-item-name {
  display: none !important;
}

.hide-tool-tip-text .recharts-tooltip-item-separator {
  display: none !important;
}

.line-plot-no-data {
  display: flex;
  width: 100%;
  justify-content: center;
}
