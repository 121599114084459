.fulfillment-list-main {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.fulfillment-list-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  color: #000;
  font-size: 14px;
  letter-spacing: 1px;
}

.fulfillment-list-loader > i {
  margin-bottom: 15px;
}

@media only screen and (max-width: 750px) {
  .fulfillment-list-main {
    margin: 5px;
  }
}
