.statistics-product-table-main {
  width: 100%;
  margin-top: 5px;
}

.statistics-product-table-main > div > table {
  width: 100%;
  border: 1px solid #aeaeae;
  text-align: left;
  padding: 5px;
  border-collapse: collapse;
}

.statistics-product-table-main tr {
  border-bottom: 1px solid #aeaeae !important;
}

.statistics-product-table-main th {
  text-align: left;
  padding: 5px 5px 5px 10px;

  font-weight: 400;
}

.statistics-product-table-main td {
  text-align: left;
  min-width: 50px;
  padding: 5px 5px 5px 10px;
  font-weight: 200;
}

.statistics-product-table-main tr:hover {
  background-color: #e6e6e6;
  cursor: pointer;
}

.internal-statistics-filter-main {
  display: flex;
}

.internal-statistics-filter-main > p {
  margin: 5px 10px 5px 0px;
  font-size: 14px;
}

.internal-statistics-filter-main > div {
  display: flex;
  align-items: center;
}

.internal-statistics-filter-main-filter-input {
  margin: 0px 5px;
  width: 150px;
  height: 24px;
}

@media only screen and (max-width: 700px) {
  .statistics-product-table-hidden-on-sm {
    display: none;
  }

  .internal-statistics-filter-main {
    display: flex;
    flex-direction: column;
  }

  .internal-statistics-filter-main > div {
    width: 50%;
  }

  .internal-statistics-filter-main-filter-input {
    width: 100%;
    margin: 0;
  }

  .statistics-center-filter-container > div > input {
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 500px) {
  .internal-statistics-filter-main > div {
    width: 100%;
  }
}
