.button-main {
  color: #ffffff;
  font-weight: 900;
  font-size: 14px;
  text-align: center;

  border-radius: 0px;

  border: 0px solid #fff;
  box-shadow: #fff;

  cursor: pointer;

  height: 25px;
  width: 100%;
  padding: 3px 3px;
}

.button-main:disabled {
  cursor: default;
}

.button-primary {
  background-color: #2eb411 !important;
}

.button-primary:disabled {
  background-color: #85bb79 !important;
}

.button-secondary {
  background-color: #ce3c25;
}

.button-secondary:disabled {
  background-color: #d16b5b;
}

.button-tertiary {
  background-color: #a5a5a5;
  color: #2f2f2f;
}

.button-tertiary:disabled {
  background-color: #c3c3c3a2;
  color: #2f2f2fbd;
}

.button-quaternary {
  background-color: #2f2f2f;
}

.button-quaternary:disabled {
  background-color: #c3c3c3a2;
  color: #2f2f2fbd;
}

.button-quinary {
  background-color: #1b3bf0;
}

.button-quinary:disabled {
  background-color: #c3c3c3a2;
}

.button-sextary {
  color: #ffffff;
  background-color: #eb5b1c;
}

.button-sextary:disabled {
  color: #ffffff;
  background-color: #c3c3c3a2;
}

.button-flagged {
  background-color: #2eb411;
}

.button-not-flagged {
  background-color: #ce3c25;
}

.button-needs-attention {
  background-color: #ce3c25;
}

.button-needs-attention:disabled {
  background-color: #85bb79;
}

.button-icon {
  /*height: 20px;
    width: 19px;*/
  margin: 0px 0px 0px 0px;
  font-size: 20px;
}

.button-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
}

.button-just-text {
  padding: 0px 5px;
}