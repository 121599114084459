.visit-item-main-content {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  justify-content: center;
  /* margin: 0 100px; */
  margin: 16px 0;
}

.visit-item-main-content .address-search-container-box {
  margin: 0 0 5px 0;
  align-items: stretch;
}

.visit-item-main-content
  .address-search-container-box
  .address-search-container-box-icon {
  width: 26px;
  flex: 1;
  height: auto;
}

.address-search-container-box > input {
  margin-bottom: 0 !important;
}

.visit-item-main-content .address-search-container-autocomplete-container {
  max-width: 100%;
  width: 100%;
  left: 0;
}

.address-form-main {
  display: flex;
  flex-direction: column;
}

.address-form-line {
  display: flex;
  flex-direction: row;
}
.address-form-main .address-form-line {
  margin-bottom: 8px;
  gap: 8px;
}

.address-form-line-content-with-error {
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: start;
  align-self: flex-end;
  width: 100%;
}

.address-form-main .address-form-line--labelled {
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: start;
  width: 100%;
}

.address-form-main .address-form-line--labelled > .address-search-container {
  width: 100%;
  margin-top: 0;
}

.address-form-main
  .address-form-line--labelled
  .address-search-container-box
  > input {
  text-indent: 0 !important;
}

.address-form-main .address-form-line-content-with-error label,
.address-form-line--labelled label {
  margin-top: 5px;
}

.address-form-main
  .address-form-line-content-with-error
  .input-field-error-message-text {
  margin-left: 0;
}

.address-form-line input {
  background-color: #f6f6f6;
  border: 1px solid #ffffff;
  font-size: 14px;
  font-weight: 200;
  padding-left: 15px;
  letter-spacing: 1px;
  height: 35px;
  width: 100%;
  padding-bottom: 1px;
  /* margin-bottom: 5px; */
  box-sizing: border-box;
}

.address-form-line select {
  background-color: #f6f6f6;
  border: 1px solid #ffffff;
  font-size: 14px;
  font-weight: 200;
  text-indent: 15px;
  letter-spacing: 1px;
  height: 35px;
  width: 100%;
  padding-bottom: 1px;
  /* margin-left: 8px; */
  /* margin-bottom: 5px; */
  box-sizing: border-box;
}

.address-form-line textarea {
  background-color: #f6f6f6;
  border: 1px solid #ffffff;
  font-size: 14px;
  font-weight: 200;
  text-indent: 15px;
  letter-spacing: 1px;
  height: 50px;
  width: 100%;
  padding: 15px 0;
  box-sizing: border-box;
  padding-bottom: 1px;
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
}

.visit-item-address-editor-buttons-actions {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 16px;
  justify-content: center;
  flex-wrap: wrap;
}

.visit-item-address-editor-buttons-actions > button {
  max-width: 300px;
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
