.fulfillment-page-main {
  margin: 0px 20px 20px 20px;
}

.fulfillment-page-main > h3 {
  margin: 0px 5px 0 0;
  display: inline-block;
}

.fulfillment-details {
  display: flex;
  justify-content: space-between;
}

.fulfillment-details-time-information {
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  margin: auto 0;
}

.fulfillment-details > div > p {
  font-weight: 200;
  text-align: left;
  vertical-align: middle;
  margin: 0px;
}

.fulfillment-page-header-buttons {
  display: flex;
  justify-content: space-between;

  padding: 10px 3px;
}

.fulfillment-page-header-buttons-information {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fulfillment-page-arrow-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fulfillment-page-arrows {
  padding: 4px 12px;
}

.fulfillment-page-arrows-disabled {
  color: gray;
}

.fullfilment-page-header-button {
  max-width: 140px;
  margin-right: 40px;
  margin-bottom: 5px;
}

.fulfillment-page-red-color {
  color: red;
}

.fulfillment-header-nfe-link {
  background-color: #a5a5a5;
  color: #2f2f2f;
  max-width: 140px;
  margin-bottom: 5px;
  height: 25px;
  font-size: 14px;
  text-align: center;
  font-weight: 900;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 750px) {
  .fulfillment-page-main {
    margin: 0px 3px 10px 3px;
  }

  .fulfillment-details > div > p {
    font-weight: 200;
    font-size: 14px;
    display: inline-block;
  }

  .fulfillment-details > button > .button-row {
    padding: 0px !important;
  }

  .fulfillment-page-main > h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 550px) {
  .fulfillment-details {
    display: flex;
    flex-direction: column;
  }

  .fulfillment-details div > p {
    margin: 5px 3px;
    font-weight: 200;
    font-size: 14px;
    display: inline-block;
  }
}
