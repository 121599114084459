.issue-list-main {
  width: 47%;
  max-height: 150px;
  overflow-y: scroll;
}

.issue-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  margin-bottom: 10px;
}

.issue-item > button {
  width: 35%;
}

.issue-text {
  width: 60%;
}

.issue-text > p {
  margin: 0px 5px 0px 0px;
}

.issue-solution {
  color: #128222;
}

@media only screen and (max-width: 800px) {
  .issue-list-main {
    width: 100%;
    max-height: 150px;
    overflow-y: scroll;
  }

  .issue-item > button {
    width: 77px;
    font-size: 12px;
  }

  .issue-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 50px;
    margin-bottom: 10px;
  }
}
