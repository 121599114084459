.business-header {
  display: flex;
  margin-bottom: 2rem;
  width: 100%;
}

.business-header-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 2px solid rgba(30, 30, 30, 0.205);
  padding: 1rem 2rem;
  align-items: center;
}

.business-header-page-name {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 700;
  width: 200px;
}

.business-header-input-container {
  position: relative;
}

.business-header-input-container input {
  padding-left: 30px;
  border-radius: 20px;
  border: 1px solid #ccc;
  height: 40px;
  width: 400px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.business-header-input-container .business-header-input-search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #555;
}
