.login-main {
  width: 100% !important;
}

.login-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /*margin: 0px 0px 50px 0px;*/
  width: 100%;
}

.login-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.login-button-container {
  display: flex;
  flex-direction: column;

  margin: 15px 5px 0px 5px;
}
