.hamburger-menu {
  position: absolute;
  top: 50px;
  left: 0px;

  width: 100%;
  height: calc(
    100% - 50px
  ); /* Minus the height of the header since element is relative to body */
  z-index: 1000;
}

.hamburger-menu-outer {
  position: absolute;
  /*top: 74px;
  left: 0px;*/

  width: 100%;
  height: 100%;

  background-color: #f8f8f8;
  opacity: 0.7;
}

.hamburger-menu-main {
  position: absolute;
  /*top: 74px;
  left: 0px;*/

  background-color: #ffffff;
  color: #212121;
  border: 1px solid #d5d5d5;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;

  width: 100%;
  max-width: 350px;
  padding: 5px 5px;

  z-index: 100;
}

.hamburger-menu-main > a {
  background-color: #ededed;
  color: #212121;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 1px;
  text-decoration: none;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 45px;
}

.hamburger-menu-main > a:hover {
  text-decoration: none;
  color: #212121;
}

.hamburger-menu-main > a:focus {
  text-decoration: none;
}
