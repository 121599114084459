.side-bar {
  padding: 2rem .5rem;
  background-color: #981E0B;
  display: flex;
  width: 4%;
  align-items: center;
  flex-direction: column;
  gap: 3rem;
}

.side-bar img {
  width: 35px;
}

.side-bar-link {
  padding: 6px 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.side-bar-link-with-background {
  background-color: rgba(222, 49, 22, 1);
}
