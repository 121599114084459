:root {
  --map-marker-width: 12px;
  --map-marker-height: 12px;
}

.map-marker {
  position: absolute;
  width: var(--map-marker-width);
  height: var(--map-marker-height);
  left: calc(var(--map-marker-width) / -2);
  top: calc(var(--map-marker-height) / -2);

  border: 3px solid #eb5b1e;
  border-radius: var(--map-marker-height);
  background-color: white;
  text-align: center;
  color: #3f51b5;
  font-size: 10px;
  font-weight: bold;
  padding: 4px;
}
