.mfa-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    height: 55px !important;
  }
}


.mfa-form{
  max-width: 300px;
}

