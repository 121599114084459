.issue-creator {
  width: 47%;
}

.issue-creator-add {
  width: 47%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.issue-creator-add > button {
  width: 300px;
}

.issue-creator-main {
  display: flex;
  flex-direction: column;

  margin: 10px 0px 10px 0px;
  font-size: 14px;
  font-weight: 200;
}

.issue-title {
  font-size: 14px;
  margin: 0px;
}

.issue-input {
  width: 100%;
  margin: 5px 0px 10px 0px;
}

.issue-creator-main > div > select {
  height: 24px;
  width: 100%;
}

.issue-creator-main > div > input {
  height: 20px;
  border: 1px solid #8e8e8e;
  border-radius: 4px;
  margin-top: 2px;
  text-indent: 2px;
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .issue-creator {
    width: 100%;
  }

  .issue-creator-add {
    width: 100%;
  }

  .issue-creator-add > button {
    width: 100%;
    max-width: 300px;
    font-size: 12px;
  }
}
