.observation-item-main {
  border: 1px solid #5e5e5e;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 200;
  padding: 10px 20px;
  margin: 10px 0px;

  /*height: 450px;
  overflow-y: scroll;*/
}

.observation-editor-text {
  background-color: #bbcbfe;
  font-size: 14px;
  border: 1px solid #a5a5a5;
  width: 99.5%;
  height: 50px;
  resize: none;
  margin-bottom: 0;
}

.observation-editor-button {
  width: 250px;
}

.observation-item-main > div {
  margin-bottom: 5px;
}

.observation-item-main > h3 {
  margin: 0px 0px 5px 0px;
}

.observation-item-line {
  display: flex;
  flex-direction: column;
  margin: 15px 0px;
}

.observation-item-line > div {
  margin-right: 5px;
}