.visit-list-main {
  overflow-y: auto;
  height: 80vh;
}

.visit-list-alert-no-unrouted-visit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  font-size: 11px;
  text-align: center;
}
