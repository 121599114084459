.route-container-flex {
  display: flex;
  padding: 10px 20px 10px 20px;
  height: 90vh;
}

.route-page-alert-size-of-window {
  display: none;
}

.route-internal-visits-content {
  border-left: 1px solid black;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  width: 18%;
}

.route-internal-routes-content {
  border: 1px solid black;
  width: 41%;
}

.route-internal-map-content {
  border-right: 1px solid black;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  width: 41%;
  display: flex;
  flex-direction: column;
}

.route-internal-content-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
}

.route-container-flex .periodic-getter-phrase {
  font-size: 11px;
  text-align: center;
  display: flex;
  align-items: center;
}

.route-internal-title-content {
  text-align: left;
  padding-left: 10px;
  font-weight: lighter;
  font-size: 18px;
}

.route-internal-list {
  height: 92vh;
}

.route-internal-name-calendar-content {
  display: flex;
  flex-direction: row;
}

.route-internal-calendar-content {
  display: flex;
  align-items: center;
  width: 250px;
  margin-left: 5px;
}

.route-page-visits-buttons-container {
  align-self: center;
}

.route-page-menu-button {
  border: none;
  background-color: white;
  color: black;
  font-size: 15px;
  cursor: pointer;
  border-radius: 50%;
  outline: none;
}

.virtual-route-section {
  width: 50%;
}

.virtual-route-info-weight-capacity {
  float: left;
  width: 50%;
}

.virtual-route-total-capacity {
  float: left;
  display: flex;
  flex-wrap: wrap;
  width: 50%;
}

.virtual-route-total-weight {
  float: left;
  display: flex;
  flex-wrap: wrap;
  width: 50%;
}

.virtual-route-info-label {
  font-size: 12px;
  padding: 0em 0.8em;
  text-align: center;
  width: 100%;
}

.virtual-route-info-value {
  font-size: 12px;
  padding: 1em 0em;
  text-align: center;
  width: 100%;
}

.virtual-route-info-value.warning {
  color: red;
  font-weight: bold;
}

.virtual-route-create-routes-button {
  float: left;
  text-align: right;
  width: 50%;
}

.virtual-route-page-add-virtual-route-button {
  border: none;
  background-color: white;
  color: black;
  font-size: 15px;
  cursor: pointer;
  border-radius: 50%;
  outline: none;
}

.route-page-router-button {
  border: none;
  background-color: white;
  color: black;
  font-size: 15px;
  cursor: pointer;
  border-radius: 60px;
  outline: none;
  padding: 0.4em 0.48em;
}

.route-page-router-button-selected {
  border: none;
  background-color: black;
  color: white;
  font-size: 15px;
  cursor: pointer;
  border-radius: 60px;
  outline: none;
  padding: 0.4em 0.48em;
}

.route-page-select-all-button {
  background-color: white;
  color: black;
  font-size: 15px;
  cursor: pointer;
  outline: none;
  border: none;
  padding-right: 0.8em;
}

.routing-label {
  font-size: 12px;
  padding: 0em 0.8em;
}

.routing-input {
  width: 70px;
}

.routing-vehicles-select {
  top: 0px;
  left: 0px;
  width: 95%;
  overflow: visible;
  display: inline-block;
  padding-left: 0.8em;
  padding-right: 0.8em;
  padding-top: 0.3em;
  font-size: 12px;
  vertical-align: middle;
}

.routing-button {
  white-space: nowrap;
  display: inline-block;
  padding-left: 0.8em;
  padding-right: 0.8em;
  height: 100%;
  vertical-align: middle;
}

.routing-create-routes {
  width: 100%;
}

.routing-form-line-content-with-error {
  flex-direction: column;
  align-items: flex-end;
  align-self: flex-end;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
}

.route-page-disabled-menu-button {
  cursor: auto;
}

.route-page-add-visit-and-order-button {
  width: 50px;
  margin: 0 5px;
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  align-items: center;
}

.route-page-red-color {
  color: #ce3c25;
}

@media only screen and (max-width: 1024px) {
  .route-container-flex {
    display: none;
  }

  .route-page-alert-size-of-window {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
