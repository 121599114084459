.route-list-main {
  overflow-y: auto;
  height: 80vh;
  margin: 0 1px;
}

.route-list-main-expandable-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 3px;
  padding: 5px;
  vertical-align: middle;
  cursor: pointer;
  border-top: 1px solid;
}

.route-list-main-expandable-menu > p {
  text-transform: capitalize;
  font-size: 12px;
}

.route-list-main-expandable-menu > i {
  font-size: 12px;
  margin: auto 0;
}

.route-list-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.route-list-alert-no-routes {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  font-size: 11px;
  text-align: center;
}

.route-list-no-show-div {
  display: none;
}
