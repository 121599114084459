.login-box-main {
  background-color: #ffffff;
  text-align: center;

  display: flex;
  flex-direction: column;
  max-width: 300px;
  padding: 0px 10px;
}

.login-box-main > h3 {
  color: #292b2c;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 1px;

  margin: 0px 0px 10px 0px;
}

.login-box-input {
  background-color: #f6f6f6;
  border: 1px solid #ffffff;
  font-size: 14px;
  font-weight: 200;
  text-indent: 15px;
  letter-spacing: 1px;

  height: 55px;
  width: 100%;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.login-box-button > button {
  height: 55px !important;
}

@media only screen and (max-width: 800px) {
  .login-box-input {
    font-size: 16px;
  }
}
