.statistics-filter-main {
  display: flex;
  width: 100%;
}

.statistics-filter-main > .statistics-filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
  margin-right: 5px;
}

.statistics-filter-main > .statistics-filter-container > p {
  font-size: 14px;
  margin: 0px 0px 2px 0px;
}

.react-datepicker__input-container > input {
  border: 1px solid #aeaeae;
  border-radius: 4px;
  padding: 4px;
  text-align: center;
  width: 90px;
}

.react-datepicker__input-container > input:hover {
  cursor: pointer !important;
}

.react-datepicker__triangle {
  display: none;
}

.statistics-filter-main > .fa-spinner {
  margin-left: 5px;
}

.statistics-center-filter-container {
  display: flex;
}

.statistics-center-filter-container > div > input {
  margin: 0 5px;
  width: 150px;
  height: 24px;
}

.statistics-center-filter-container > div {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
}

.statistics-center-filter-container > div > p {
  margin: 5px 10px 5px 0px;
  font-size: 14px;
}

.statistics-center-filter-container > div > select {
  width: 100%;
  height: 24px;
  margin-top: 2px;
}

.statistics-center-filter-container > div > select:hover {
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .statistics-filter-main {
    flex-direction: column;
  }
  .statistics-center-filter-container {
    flex-direction: column;
  }
  .statistics-center-filter-container > div {
    margin: 5px 0px;
    width: 50%;
  }

  .statistics-center-filter-container > div > select {
    width: 100%;
  }

  .statistics-filter-main > .statistics-filter-container {
    width: 50%;
  }
}

@media only screen and (max-width: 500px) {
  .statistics-center-filter-container > div {
    width: 100%;
  }

  .statistics-filter-main > .statistics-filter-container {
    width: 100%;
  }
}
