@keyframes blinking {
  0% {opacity: 100;}
  50% {opacity: 0;}
  100% {opacity: 100;}
}

.payment-exceed {
  font-size: 18px;
  color: #ff0000;
  animation-name: blinking;
  animation-duration: 1s;
  animation-iteration-count: 10;
  animation-fill-mode: forwards;
  margin: auto 5px auto auto;
}