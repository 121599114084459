.order-fraud-main {
  border: 1px solid #5e5e5e;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 200;
  padding: 10px 20px;
  margin: 10px 0px;

  /*height: 450px;
  overflow-y: scroll;*/
}

.order-fraud-main > h3 {
  margin: 0 0 5px 0;
}

.address-item-line {
  margin: 5px 0 10px 0;
}
