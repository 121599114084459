.statistics-route-travel-table-main {
  width: 100%;
  margin-top: 15px;
}

.statistics-route-travel-table-main > div > table {
  width: 100%;
  border: 1px solid #aeaeae;
  text-align: left;
  padding: 5px;
  border-collapse: collapse;
}

.statistics-route-travel-table-main tr {
  border-bottom: 1px solid #aeaeae !important;
}

.statistics-route-travel-table-main th {
  text-align: left;
  padding: 5px 5px 5px 5px;

  font-weight: 400;
}

.statistics-route-travel-table-main td {
  text-align: left;
  min-width: 50px;
  padding: 5px 5px 5px 5px;
  font-weight: 200;
}

.statistics-route-travel-table-main tr:hover {
  background-color: #e6e6e6;
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .statistics-route-travel-table-hidden-on-sm {
    display: none;
  }
}
