.editable-task-item-field {
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.editable-task-item-button {
  width: 32px;
  float: right;
  border: none;
  background-color: #ffffff;
  color: #ce3c25; /* White text */
  font-size: 15px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
  border-radius: 50%;
}

.editable-task-item-field > input {
  background-color: #f6f6f6;
  border: 1px solid #ffffff;
  font-size: 14px;
  font-weight: 200;
  text-indent: 15px;
  letter-spacing: 1px;
  height: 35px;
  width: 100%;
  padding-bottom: 1px;
  margin-bottom: 5px;
  box-sizing: border-box;
}

.editable-task-item-deliver-task-description {
  font-size: 14px;
  margin: 5px;
  letter-spacing: 2px;
  word-spacing: 3px;
  width: 100%;
}
