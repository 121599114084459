.address-item-main {
  border: 1px solid #5e5e5e;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 200;
  padding: 10px 20px;
  margin: 10px 0px;

  /*height: 450px;
  overflow-y: scroll;*/
}

.address-item-header {
  display: flex;
}
.address-item-header > h3 {
  margin: 8px 10px 0 0;
}

.address-item-header > a:link,
a:visited {
  text-decoration: none;
}
.address-item-header > a:hover {
  text-decoration: none;
}
.address-item-header > a:active {
  text-decoration: none;
}

.fa-map-marker {
  color: #000000;
}

.address-item-map-icon {
  font-size: 20px;
  cursor: pointer;
  border: 0.5px solid #585858;
  padding: 4px;
  margin-right: 10px;
}
.address-item-copy-icon {
  font-size: 20px;
  cursor: pointer;
  border: 0.5px solid #585858;
  padding: 4px;
}

.address-item-main > div {
  margin-bottom: 5px;
}

.address-item-main > h3 {
  margin: 0px 0px 5px 0px;
}

.address-container-flex {
  display: flex;
  justify-content: space-between;
  background-color: #d5d5d5;
  padding: 15px;
}

.address-item-line {
  display: flex;
  flex-direction: column;
  margin: 15px 0px;
  flex-wrap: wrap;
}

.address-item-line > div {
  margin-right: 5px;
}

.address-container-flex-initial-information {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 600px) {
  .address-container-flex {
    flex-direction: column;
  }
}
