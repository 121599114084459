.switcher-component {
  display: flex;
  justify-content: space-between;
  margin: 2px;
  align-items: center;
}

.switcher-component > span {
  margin: 0;
  font-size: 14px;
  letter-spacing: 1px;
}

.switcher-component-internal-switcher {
  display: flex;
  flex-direction: column;
}

.switcher-component-internal-switcher > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.switcher-component-internal-switcher-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
}

.switcher-component-internal-switcher-helper > i {
  font-size: 12px;
  margin-left: 5px;
}

.switcher-component-internal-switcher-content > p {
  margin: 0;
  font-size: 9px;
  text-transform: uppercase;
}
