.route-item {
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin: 0 2px 10px;
  padding: 5px;
  width: 47%;
}

.route-item-edit {
  width: 32px;
  float: right;
  border: none;
  background-color: white;
  color: #ce3c25;
  /* White text */
  font-size: 15px;
  /* Set a font size */
  cursor: pointer;
  /* Mouse pointer on hover */
  border-radius: 50%;
}

.route-item-info-show {
  width: 32px;
  float: right;
  border: none;
  background-color: white;
  color: black;
  /* White text */
  font-size: 15px;
  /* Set a font size */
  cursor: pointer;
  /* Mouse pointer on hover */
  border-radius: 50%;
}

.route-item-edit:disabled {
  color: #aaaaaa;
}

.route-item-info-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 0em;
}

.route-item-info-line.padding {
  padding-left: 1.5em;
}

.route-item-info-line-space-around {
  justify-content: space-around;
}

.route-item-info-line>div {
  display: flex;
}

.route-item-info-line>i {
  font-size: 10px;
  margin-left: 3px;
}

.route-item-info-line .route-item-info-line-p-content {
  font-size: 10px;
  margin-left: 5px;
}

.route-item-info-line .route-item-info-line-bold {
  font-weight: bolder;
}

.route-item-info-line .route-item-info-line-centered {
  margin-left: 20%;
}

.no-visitis-yet-route-item {
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin: 20px;
  padding: 5px;
  width: auto;
  height: 100px;
  background-color: lightgray;
}

.route-modal-style {
  position: absolute;
  left: 40px;
  right: 40px;
  top: 40px;
  bottom: 40px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(235, 91, 30);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  width: 500px;
  height: 300px;
  transform: translate(60%, 50%);
}

.route-modal-title {
  font-size: 20px;
  text-align: center;
  letter-spacing: 2px;
  word-spacing: 3px;
}

.route-modal-edit-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.route-modal-edit-content>.route-modal-edit-content-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.route-modal-edit-content>.route-modal-edit-content-line>p {
  width: 300px;
  text-align: start;
}

.route-modal-edit-content>.route-modal-edit-content-line>select {
  display: flex;
  width: 100px;
  height: 24px;
  margin-top: 2px;
}

.route-modal-edit-content>.route-modal-edit-content-line>select:hover {
  cursor: pointer;
}

.route-item-state-properties {
  line-height: 0em;
  font-size: 12px;
  white-space: pre-wrap;
  float: right;
  margin-right: 10px;
}

.route-item-state-color-alert,
.third-party-delivery-status-cancelled-with-charge,
.third-party-delivery-status-cancelled,
.third-party-delivery-status-dropped-then-cancelled {
  color: #ce3c25;
}

.route-item-state-color-inroute,
.third-party-delivery-status-dropped {
  color: #eb5b1e;
}

.route-item-state-color-finished,
.third-party-delivery-status-finished {
  color: green;
}

.third-party-delivery-status-cancelled-with-charge,
.third-party-delivery-status-cancelled,
.third-party-delivery-status-dropped-then-cancelled,
.third-party-delivery-status-dropped,
.third-party-delivery-status-finished {
  font-weight: bolder;
}

.route-modal-edit-spinner {
  margin-left: 10px;
}

.route-item-modal-loading {
  display: flex;
  height: 16px;
  width: 16px;
  margin: 10px;
}

.third-party-delivery-logo {
  margin-left: 5px;
  margin-right: 5px;
  max-width: 3em;
  height: auto;
}

.third-party-delivery-logo.disabled>path {
  fill: #bababa;
}

.third-party-delivery-create-order-button {
  border: none;
  background-color: white;
  cursor: pointer;
  border-radius: 60px;
  outline: none;
}

.MuiButton-text {
  padding: 6px 4px !important;
}

.MuiButton-root {
  min-width: 32px !important;
}

.route-icon {
  width: 1em;
  min-width: 1em;
}

.route-info-driver>i {
  font-size: 10px;
  margin-left: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 0em;
}

.big-route {
  background: url(../assets/route-solid.svg) no-repeat top left;
  background-size: contain;
  width: 1em;
  cursor: pointer;
  min-width: 0.5em;
  min-height: 0.5em;
}