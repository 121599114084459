.review-item-address-wrapper {
  display: flex;
  flex-direction: column;
  margin: 16px 0px;
}

.review-item-address-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.review-item-address-button {
  max-width: 150px;
}

.review-item-address-wrapper .address-form-line--labelled {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.review-item-address-wrapper .input-label-text {
  font-size: 14px;
}

.review-item-address-textarea {
  padding: 15px 0;
  min-height: 100px;
}

.review-item-address-button-submit {
  max-width: 300px;
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px auto;
}

.review-item-body-text {
  font-size: 15px;
  margin-top: 0px;
}
