.line-item-main {
  display: flex;
  flex-direction: column;
  background-color: #d5d5d5;
  margin-bottom: 15px;
}

.line-item-line {
  width: 100%;
}

.line-item-image {
  height: 50px;
  width: auto;
  margin-right: 10px;
}

.line-item-content {
  font-size: 14px;
  font-weight: 200;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  height: auto;
  margin-top: 10px;
  /*margin-bottom: 10px;*/
  padding: 0px 15px;
}

.line-item-content > div > select {
  height: 24px;
  /*margin-top: 18px;*/
  width: 150px;
}

.line-item-content > div {
  width: 150px;
}

.line-item-content > div > input {
  height: 20px;
  border: 1px solid #8e8e8e;
  border-radius: 4px;
  margin-top: 2px;
  text-indent: 2px;
  width: 150px;
}

.line-item-content > p {
  margin: 0px;
  width: 150px;
}

.line-item-content > button {
  width: 150px;
}

.line-item-issue-container {
  display: flex;
  justify-content: space-between;
  max-height: 150px;
  padding: 0px 10px;
  margin-bottom: 10px;
}

.issue-divider {
  border: 0.5px solid #8e8e8e;
  margin: 5px 0px;
  /*width: 1px;
  height: 100%; 
  margin: 0px 0px;*/
}

.line-item-selector-container {
  display: flex;
  flex-direction: column;
  width: 210px !important;
}

.line-item-selector {
  display: flex;
  flex-direction: row;
  margin-bottom: 7px;
}

.line-item-selector-content {
  width: 110px !important;
  display: flex;
  align-items: center;
}

.fulfillment-item-order-number {
  font-weight: 700;
}

.line-item-mix-name {
  font-weight: 700;
}

.line-item-observation-div {
  margin: 10px 0px 0px;
  padding: 0px 15px;
}

.line-item-observation-div p {
  display: inline;
}

.line-item-observation {
  text-align: justify;
  word-break: break-all;
  overflow-wrap: break-word;
  word-wrap: break-word;
  display: inline;
}

.line-item-observation-header-text {
  font-weight: bold;
}

.line-item-pre-prep-and-prep-selectors > div {
  margin-bottom: 20px;
}

.line-item-preparation-image {
  width: 50px;
  height: auto;
  transition: 0.2s;
}

.line-item-preparation-image:hover {
  transform: scale(8);
  transition: 0.6s;
}
.line-item-preparation-image.no-image:hover {
  transform: scale(1);
}

@media only screen and (max-width: 800px) {
  .line-item-content {
    justify-content: space-around;
    margin-bottom: 10px;
  }

  .line-item-content > p {
    margin: 0px;
    width: 100%;
    max-width: 150px;
    margin: 10px 5px 0px 5px;
    text-align: center;
  }

  .line-item-order-details {
    width: 100%;
    max-width: 150px;
    margin: 10px 5px 0px 5px;
    text-align: center;
  }

  .line-item-selector-container {
    margin: 10px 5px 0px 5px;
  }

  .line-item-current-status {
    margin: 10px 5px 0px 5px;
  }

  .line-item-issue-container {
    display: flex;
    flex-direction: column-reverse;
    max-height: 500px;
    /* justify-content: space-between;
    max-height: 150px;
    padding: 0px 10px;
    margin-bottom: 10px; */
  }

  .line-item-preparation-image {
    display: none;
  }
}
