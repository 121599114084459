.filter-menu-main {
  position: absolute;
  right: 21px;
  top: 100px;

  background-color: #ffffff;
  color: #212121;
  border: 1px solid #d5d5d5;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;

  width: 100%;
  max-width: 350px;
  padding: 5px 5px;

  z-index: 100;
}
