.fulfillment-sort-page {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 1024px) {
  .fulfillment-sort-page {
    justify-content: flex-start;
    flex-wrap: wrap;
    height: auto;
  }
}
