.map-column-container {
  height: 100%;
  width: 98%;
  margin: 0 5px 0;
  display: flex;
}

.map-column-info-of-vehicle {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 16px;
  font-size: 11pt;
  text-align: center;
}

.map-column-vehicle-marker {
  position: absolute;
  font-size: 14pt;
}

.map-column-vehicle-pin-driver-info {
  font-size: 12px;
  margin: 0;
}
