.fulfillment-sort-item-main {
  display: flex;
  flex-direction: column;
  /* padding: 5; */
  margin: 0 0 5px 0;
  user-select: "none";
}

.fulfillment-sort-item-line {
  width: 100%;
}

.fulfillment-sort-item-container {
  display: flex;
}

.fulfillment-sort-item-content {
  color: black;
  text-decoration: none;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fulfillment-sort-item-content > p {
  margin: 5px 5px;
  width: 14.6%;
}

.fulfillment-sort-item-content:hover {
  background-color: #ededed;
  cursor: pointer;
}

.fulfillment-sort-item-order-number {
  text-align: left;
  display: flex;
  width: 14.6%;
}

.fulfillment-sort-item-order-number > p {
  margin-right: 15px;
}

@media only screen and (max-width: 750px) {
  .fulfillment-sort-item-content {
    justify-content: flex-start;
    flex-wrap: wrap;
    height: auto;
  }

  .fulfillment-sort-item-content > p {
    width: auto;
    margin: 5px 15px;
  }
  .fulfillment-sort-item-order-number {
    width: auto;
  }

  .fulfillment-sort-item-order-number > p {
    margin-right: 9px;
  }
}

@media only screen and (max-width: 375px) {
  .fulfillment-sort-item-content > p {
    width: auto;
    margin: 5px 8px;
  }
}
