.user-fraud-main {
  border: 1px solid #5e5e5e;
  border-radius: 4px;
  padding: 15px 20px;
  margin: 10px 0px;
  justify-content: space-around;
}

.user-fraud-content {
  display: flex;
}

.user-fraud-main-container {
  font-size: 14px;
  font-weight: 200;
  padding: 10px 20px;
  width: 40%;
}

.user-fraud-no-graph {
  width: 100% !important;
}

.chart-container {
  font-size: 14px;
  font-weight: 180;
  width: 60%;
  height: 200px;
  margin-right: 10px;
  padding: 0px 0px 70px 10px;
}

.order-fraud-item-list {
  word-break: break-word;
}

@media only screen and (max-width: 800px) {
  .user-fraud-main {
    display: flex;
    flex-direction: column;
  }

  .chart-container {
    width: 100%;
    margin-left: -10px;
    margin-right: 10px;
  }

  .user-fraud-main-container {
    width: 100%;
  }
  .user-fraud-content {
    flex-direction: column;
  }
}

.user-fraud-main > h3 {
  margin: 0 0 5px 0;
}

.address-item-line {
  margin: 5px 0 10px 0;
}
