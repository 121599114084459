.map-location-content {
  display: flex;
  flex-direction: column;
}
.map-location-content > img {
  padding: 10px;
  width: 100%;
}

.address-button-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
