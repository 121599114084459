.placement-photo-scroller-main {
  margin: 20px auto;
  padding: 15px;
  background-color: #d5d5d5;
  width: 90%;
}

.placement-photo-scroller-main .slick-prev {
  left: -40px;
}

.placement-photo-scroller-main .slick-next {
  right: -40px;
}

.placement-photo-scroller-main .slick-prev:before {
  color: black;
}

.placement-photo-scroller-main .slick-next:before {
  color: black;
}
.placement-photo-scroller-element > img {
  object-fit: cover;
  height: 200px;
  margin: auto;
  padding: 0 10px;
}

@media only screen and (max-width: 750px) {
  .placement-photo-scroller-main {
    display: none;
  }
}
